#hallenbelegungsplan {
	form {
		#tag {
			width: 130px;
		}
		.vk-prev {
			margin-right: 10px;
		}
		.vk-week {
			display: inline-block;
			font-weight: bold;
			white-space: nowrap;
		}
		.vk-next {
			margin-left: 10px;
		}
	}
	#time-column {
		float: left;
		text-align: center;
		width: 65px;
		
		.time {
			border: 1px solid #e3e3e2;
			font-size: 15px;
			height: 61px;
			line-height: 15px;
			margin-top: -1px;
			padding-top: 7px;
			
			&.corner {
				background-color: #f2f2f2;
				margin-top: 0;
			}
		}
	}
	#kalender-buttons {
		position: relative;
		@include media-breakpoint-down(xs) {
			height: 50px;
		}
		
		.prevArrow, .nextArrow {
			position: absolute;
			bottom: 15px;
			width: 30px;
			height: 30px;
			z-index: 999;
			background-color: $primary;
			border-radius: 15px;
			
			&.slick-disabled {
				opacity: 0.7;
			}
			svg {
				width: 20px;
				height: 20px;
				position: absolute;
				top: 4px;
				left: 4px;
				fill: none;
				stroke: #fff;
				stroke-width: 2;
				stroke-linecap: round;
				stroke-linejoin: round;
			}
		}
		.prevArrow {
			right: 45px;
			
			svg {
				transform: rotate(90deg);
				transform-origin: center;
			}
		}
		.nextArrow {
			right: 0;
			
			svg {
				transform: rotate(-90deg);
				transform-origin: center;
				top: 5px;
				left: 6px;
			}
		}
	}
	#sporthallen-kalender {
		border: 1px solid #e3e3e2;
		border-width: 0 1px 0 0;
		font-size: 15px;
		margin-left: 65px;
		margin-bottom: 20px;
		
		.time {
			background-color: #f2f2f2;
			border: 1px solid #e3e3e2;
			border-width: 1px 0;
			color: #002c4c;
			font-size: 18px;
			height: 61px;
			line-height: 25px;
			margin: 0;
			padding-top: 5px;
			position: relative;
			text-align: center;
		}
		.slick-slide {
			border: 1px solid #e3e3e2;
			border-width: 0 1px 1px 0;
			height: 901px;
			position: relative;
		}
		.calItem {
			border: 1px solid #e3e3e2;
			border-width: 0 1px 1px 0;
			color: #fff;
			font-weight: 400;
			overflow: hidden;
			padding: 5px;
			position: absolute;
			width: 100%;
			
			a {
				color: #fff;
				text-decoration: underline;
			}
		}
		a.buchung {
			display: block;
			height: 901px;
			position: absolute;
			text-decoration: none;
			width: 100%;
		}
		.slick-slide {
			& > div, .free, .hatched {
				height: 100%;
			}
		}
	}
	#sporthallen-kalender, #legende {
		.belper {
			background: #0e78ad;
			background: linear-gradient(135deg,#0e78ad,#0c6c9c);
			z-index: 5;
		}
		.bel {
			background: #2caced;
			background: linear-gradient(135deg,#2caced,#1293d4);
			z-index: 10;
		}
		.sperr {
			background: #e2001a;
			background: linear-gradient(135deg,#e2001a,#af0014);
		}
		.hatched {
			background: url(/img/bg_hatched.png);
		}
	}
	#legende {
		padding-bottom: 20px;
		font-size:14px;
		line-height:1.6;
		
		span {
			border: 1px solid #000;
			display: inline-block;
			height: 20px;
			width: 20px;
			
			&.hatched {
				border: 1px solid #000 !important;
				width: 20px !important;
			}
		}
	}
}
.svg-icon {
	position: absolute;
	width: 0;
	height: 0;
	pointer-events: none;
	user-select: none;
}