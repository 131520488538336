$primary: #2880D7;
$secondary: #BBBBBB;
$secondary-link-color: #768837;
$link-color: #2880D7;
$pagination-disabled-color: rgba(1,1,1,0.3);

/* roboto-condensed-regular - latin */
@font-face {
	font-family: 'Roboto Condensed';
	font-style: normal;
	font-weight: 400;
	src: url('/fonts/roboto-condensed-v18-latin-regular.eot'); /* IE9 Compat Modes */
	src: local('Roboto Condensed'), local('RobotoCondensed-Regular'),
		url('/fonts/roboto-condensed-v18-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
		url('/fonts/roboto-condensed-v18-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
		url('/fonts/roboto-condensed-v18-latin-regular.woff') format('woff'), /* Modern Browsers */
		url('/fonts/roboto-condensed-v18-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
		url('/fonts/roboto-condensed-v18-latin-regular.svg#RobotoCondensed') format('svg'); /* Legacy iOS */
}

$font-family-sans-serif: 'Arial';

$font-size-base: 0.95rem;

$headings-margin-bottom: 15px;
$headings-font-family: 'Roboto Condensed', Arial, sans-serif;
$headings-font-weight: normal;
$headings-color: $primary;

$table-font-family: 'Roboto Condensed', Arial, sans-serif;

$h1-font-size: 2em;
$h2-font-size: 1.75em;
$h3-font-size: 1.5em;

h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5 {
	&.secondary {
		color: $secondary!important;
	}
}

body {
	padding-top: 10px;
	padding-bottom: 10px;
}
.container-fluid {
	padding-left: 0!important;
	padding-right: 0!important;
}
#hallenbelegungsplan {
	
	#sporthallen-kalender, #legende {
		.bel {
			background: #62b339!important;
			background: linear-gradient(135deg,#62b339,darken(#62b339, 10%))!important;
			z-index: 10;
		}
		.custom {
			background: lighten(orange, 5%);
			background: linear-gradient(135deg,lighten(orange, 5%),darken(orange, 5%));
			z-index: 10;
		}
	}
}

//auswahl
.pagination {

	.page-item {
		font-size: $font-size-lg;
		color: $secondary;
		user-select: none;

		.page-link {
			border: none;
			display: inline;
			padding: 0.5rem 0.5rem;
		}
		.page-link:hover {
			background-color: transparent;
			color: $black;
			text-decoration: underline;
		}
		.page-link:focus {
			box-shadow: none;
		}
	}
	.page-item::after {
		content: "|";
	}
	.page-item:last-child:after
	{
		content: '';
	}
}

//liste
.suchergebnis {
	color: $secondary;
	font-size: 30px;
	line-height: 30px;
}

$list-group-item-padding-y: 0.75rem;
$list-group-item-padding-x: 0;
.list-group-item {
	h3 {
		margin-bottom: 5px;
		font-size: 22px;
		line-height: 26px;
		
		a {
			color: $primary;
			font-weight: normal;
		}
	}
	p {
		margin-bottom: 0.5rem;

		a {
			color: $secondary-link-color;
			
			&:hover {
				color: #9CBE16;
			}
			&::before {
				content: "» ";
			}
		}
	}
}

//links
a {
	font-weight: 700;
}

//border radius
$border-radius: 0;
$border-radius-lg: 0;
$border-radius-sm: 0;

//buttons
$btn-padding-y: 7px;
$btn-padding-x: 15px;
$btn-font-size: 16px;
//$btn-line-height: normal;
$btn-font-weight: normal;
//$btn-box-shadow: none;
$btn-focus-width: 0;
//$btn-disabled-opacity: 0.55;
//$btn-link-disabled-color: lighten($dark, 15%);
$btn-border-radius: 2px;

.vk-prev, .vk-next {
	color: $secondary-link-color;
	font-weight: 700 ;
	
	&:hover {
		color: #9CBE16;
	}
	&:focus {
		outline: none;
	}
}

//forms
$input-color: #555555;
$input-bg: $white;
$input-border-color: #8A8A8A;
//$input-line-height: 2;
$input-padding-y: .375rem;
$input-padding-x: .5rem;
$label-margin-bottom: 5px;
$form-group-margin-bottom: 5px;
legend {
	color: $secondary!important;
}
.form-control, .custom-select {
	box-shadow: inset 2px 2px 7px 0px #CACACA;
}

//table
$table-cell-padding: 8px 12px 8px 12px;

.table {
	font-family: $table-font-family;

	a {
		font-weight: 400;
		color: $secondary-link-color;
		
		&:hover {
			color: #9CBE16;
		}
	}

	th {
		font-size: 17px;
	}
}

//hallen Bild
.hall_img {
	display: block;
    margin-left: auto;
    margin-right: auto;
}

//$input-btn-focus-box-shadow: none;
//$input-focus-border-color: $input-border-color;


//Hallenbelegung Button









.legende {
	padding-left: 20px;
	position: absolute;
    top: 30px;
    right: 0;
	width: 150px;
	
	&::before {
		content: "";
		display: block;
		position: absolute;
		left: 0;
		top: 3px;
		width: 14px;
		height: 14px;
		background-color: #3471b8;
		border: 2px solid #88b2df;
		border-radius: 10px;
	}
	&.-schule {
		margin-top: 22px;
		
		&::before {
			background-color: #5ebb4f;
			border: 2px solid #bdd747;
		}
	}
}

.rechtlich{
	margin: 0 25vw;
	position: absolute;
}

.datenschutz{
	margin: 0;
	position: absolute;
}

.impressum{
	margin: 0 200px;
	position: absolute
}




